import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  webHeader: {
    [theme.breakpoints.down("sm")]: { display: "none" },
  },
  appBar: {
    backgroundColor: "#fff !important",
    zIndex: theme.zIndex.drawer + 1,
    "& *": { fontFamily: "Product Sans" },
    "& a": { textDecoration: "none", color: "inherit" },
  },
  menuIconButton: {
    color: "#fff !important",
  },
  appContainer: { width: "100%", margin: "0 auto", maxWidth: 1200 },

  topPhoneNumbers: {
    "& a": { fontFamily: "Product Sans" },
  },

  toolbar: {
    backgroundColor: "#fff",
    height: 120,
    [theme.breakpoints.down("sm")]: {
      padding: "15px 0",
      flexDirection: "column",
      height: "fit-content",
    },
  },
  navMenu: {
    backgroundColor: "#E16434",
    color: "#000",
    justifyContent: "center",
    boxShadow: "none",
    minHeight: "55px",
    [theme.breakpoints.down("sm")]: { justifyContent: "flex-end" },
  },
  linkMenu: {
    padding: "18px 0",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  },
  navItems: {
    margin: "0 24px",
    textTransform: "uppercase",
    fontFamily: "Product Sans",
    fontWeight: 600,
    letterSpacing: "0.1em",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      margin: "0 18px",
    },
  },
  mobNavItems: {
    color: "#E16434",
  },
  verticalNavItems: {
    padding: "16px 24px",
    textTransform: "uppercase",
    textAlign: "center",
  },
  menuButton: {
    display: "none",
    fontFamily: "Product Sans",
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: { display: "block" },
    "&:hover": { color: "#E16434" },
  },
  whiteButton: {
    backgroundColor: "#E16434",
    borderRadius: 25,
    margin: "0 20px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "bold",
  },
  banner: {
    color: "#d6a65d",
    fontSize: 30,
    letterSpacing: 10,
    fontWeight: 600,
  },
  email: {
    textTransform: "capitalize",
    color: "#000",
  },
  icon: {
    color: "#000",
    "&.active": { color: "#dddddd" },
  },

  badge: { backgroundColor: "#E16434", color: "#fff", top: -5, right: -5 },

  avatar: {
    color: "#E16434",
    cursor: "pointer",
    backgroundColor: "#fff",
    width: 35,
    height: 35,
    margin: 5,
    border: "2px solid #d6a65d",
  },

  expandedMenu: { [theme.breakpoints.down("sm")]: { display: "none" } },

  box: { "& > *": { margin: "0 5px" }, alignItems: "center" },
  link: {
    textDecoration: "none",
    minWidth: "100px",
    color: "#000",
    "&.active, &:hover": { color: "#E16434" },
  },
  searchInput: {
    padding: "6px 16px",
    backgroundColor: "#E16434",
    border: "2px solid #fff",
    position: "absolute",
    top: 30,
    left: "50%",
    transform: "translateX(-50%) !important",
    zIndex: 9999,
    borderRadius: 24,
    "& input": {
      width: 300,
      fontFamily: "Product Sans",
      color: "#fff",
    },
    [theme.breakpoints.down("sm")]: {
      position: "static",
      margin: 15,
      "& input": {
        width: "fit-content",
      },
    },
  },
}));
