import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
  carouselItem: {
    width: '100%',
    height: 300,
    position: 'relative',
    display: 'grid',
    '& > img': {
      width: '100%',
      position: 'absolute',
      top: 0
    }
  },

  carouselItemContent: {
    width: '80%',
    maxWidth: 1120,
    margin: 'auto',
    zIndex: 99
  },
  button: {
    fontSize: 18,
    width: 220,
    borderRadius: 25
  }
}));
