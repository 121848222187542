import React from "react";
import { Helmet } from "react-helmet";
import { appName, appUrl, data_url } from "../../../helpers/common";

const SingleProductDetailMeta = ({ product }) => {

  const productData = {
    "@context": "http://schema.org/",
    "@type": "Product",
    name: product?.name,
    image: `${data_url}/products/${product?.id}/${product?.main_image}`,
    description: product?.description || product?.highlights,
    sku: product?.sku,
    brand: {
      "@type": "Brand",
      name: product?.brand_name || appName,
    },
    offers: {
      "@type": "Offer",
      priceCurrency: "INR",
      price: product?.selling_price,
      availability: `http://schema.org/${
        product?.available_stock > 0 ? "InStock" : "OutOfStock"
      }`,
    },
  };

  const breadcrumbData = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 0,
        item: {
          "@id": `${appUrl}`,
          name: "Home",
        },
      },
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": `${appUrl}/category/${product?.category_id}/${product?.category_name}/1`,
          name: product?.category_name,
        },
      },
      {
        "@type": "ListItem",
        position: 2,
        item: {
          "@id": `${appUrl}/product/${product?.id}/1/${product?.url_slug || product?.name}`,
          name: product?.name,
        },
      },
    ],
  };

  return (
    <Helmet>
      <script type='application/ld+json'>{JSON.stringify(productData)}</script>
      <script type='application/ld+json'>
        {JSON.stringify(breadcrumbData)}
      </script>
    </Helmet>
  );
};

export default SingleProductDetailMeta;
