import { createAction } from 'redux-actions';

import { GET_CATEGORY_SUCCESS, GET_CATEGORY_FAILURE } from '../constants/actionTypes';
import * as Categories from '../api/categories';

const getCategorySuccess = createAction(GET_CATEGORY_SUCCESS);
const getCategoryFailure = createAction(GET_CATEGORY_FAILURE);

export function getCategory() {
  return dispatch => Categories.getCategory().then(data => {
    dispatch(getCategorySuccess(data));
  }).catch(error => {
    getCategoryFailure(error);
  })
}
