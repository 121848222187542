import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPage } from "../../api/pages";
import MetaTags from "../Common/MetaTags";

import NotFound from "../NotFoundPage";

const CmsPage = () => {
  const { page } = useParams();
  const [details, setDetails] = useState({ content: "", title: "" });

  useEffect(() => {
    if (page) getPage(page).then((json) => setDetails(json.data?.[0]));
  }, [page]);

  return details ? (
    <Box maxWidth={1200} m='48px auto' px={3} fontFamily='Product Sans'>
      <Box
        color='#E16434'
        textAlign='center'
        fontSize={36}
        mb={2}
        fontWeight={700}
      >
        {details.title}
      </Box>
      <div dangerouslySetInnerHTML={{ __html: details.content }} />

      <MetaTags
        module='pages'
        title={details?.title}
        description={details?.meta_description}
        keywords={details?.meta_keyword}
      ></MetaTags>
      
    </Box>
  ) : (
    <NotFound />
  );
};

export default CmsPage;
