import CmsPage from '../components/CmsPage';
import ContactUs from '../components/ContactUs';
import ProductListing from '../components/ProductListing';
import SearchResult from '../components/SearchResult';
import Cart from '../components/Cart';
import Login from '../components/Login';
import SignUp from '../components/SignUp';
import ForgetPassword from '../components/ForgetPassword';
import VerifyEmail from '../components/SignUp/VerifyEmail';
import Shipping from '../components/Shipping';
import Checkout from '../components/Checkout';
import OrderHistory from '../components/OrderHistory';
import OrderDetails from '../components/OrderHistory/OrderDetails';
import Profile from '../components/Profile';
import Test from '../components/Test';
import SingleProduct from '../components/ProductListing/SingleProduct';


const commonRoutes = [
  { path: '/contact-us', element: <ContactUs /> },
  { path: '/page/:page', element: <CmsPage /> },
  { path: '/category/:categoryId/:currentPage/:categoryName', element: <ProductListing /> },
  { path: '/category/:categoryId/:currentPage/:categoryName/filters', element: <ProductListing /> },
  { path: '/category/:categoryId/:categoryName', element: <ProductListing /> },
  { path: '/products/:productLabel/:currentPage', element: <ProductListing /> },
  { path: '/products', element: <ProductListing /> },
  { path: '/product/:productId/:currentPage/:productName', element: <SingleProduct /> },
  { path: '/search/:keyword', element: <SearchResult /> },
  { path: '/cart', element: <Cart /> },
  { path: '/login', element: <Login /> },
]

const authProtectedRoutes = [
  { path: '/profile', element: <Profile /> },
  { path: '/shipping', element: <Shipping /> },
  { path: '/checkout/:id', element: <Checkout /> },
  { path: '/my-orders', element: <OrderHistory /> },
  { path: '/my-orders/:orderId', element: <OrderDetails /> },
];

const publicRoutes = [
  { path: '/test', element: <Test /> },
  { path: '/login', element: <Login /> },
  { path: '/signup', element: <SignUp /> },
  { path: '/verify-email', element: <VerifyEmail /> },
  { path: '/forget-password', element: <ForgetPassword /> }
];

export { authProtectedRoutes, publicRoutes, commonRoutes };
