import {
  Box,
  Divider,
  TextField
} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { updateCart } from "../../actions/cart";
import { showErrorSnackbar } from "../../actions/snackbar";
import { updateCartData } from "../../cart";
import CommonButton from "../Common/CommonButton";
import MetaTags from "../Common/MetaTags";
import SingleCartItem from "./SingleCartItem";
import useStyles from "./styles";
import { getFinalPriceAmount } from "../../helpers/common";

const Cart = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [remark, setRemark] = useState("");
  const products = useSelector((state) => state.cart.data);
  const dispatch = useDispatch();

  const checkoutOrders = () => {
    navigate("/shipping", {
      state: {
        remark,
      },
    });
  };

  const getTotal = () =>
    products?.reduce(
      (acc, product) =>
        acc +
        getFinalPriceAmount(product) *
        parseInt(product?.qty ? product?.qty : 1, 10),
      0
    );


  const removeItem = (product) => {
    const newCartData = updateCartData(products, product, 0);
    dispatch(updateCart(newCartData));

    dispatch(
      showErrorSnackbar(`Product "${product?.name}" removed from the cart`, null)
    );
  };

  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
    localStorage.removeItem("hs_coupon_discount");
  }, []);

  return (
    <Box className={classes.products} p={3}>
      <MetaTags title='Shopping Cart' description='' keywords=''></MetaTags>

      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box flex={1} fontSize={26} fontWeight={700}>
          Your Cart
        </Box>
        {products.length > 0 && (
          <>
            <Box className={classes.placeOrder}>
              <Box mr={2} fontFamily={"Product Sans"}>
                <Box fontSize={16} color='#666'>
                  Subtotal
                </Box>
                {/* <Box fontSize={18} >&#x20b9;{getTotal()}</Box> */}
              </Box>
              <CommonButton
                rounded
                width={180}
                loading={false}
                onClick={checkoutOrders}
              >
                Proceed to Buy
              </CommonButton>
            </Box>
          </>
        )}
      </Box>

      <Box mt={3}>
        {products.length > 0 ? (
          products.map((product) => (
            <SingleCartItem product={product} key={product?.id} removeItem={removeItem} products={products} />
          ))
        ) : (
          <Box color='#666' my={3} fontSize={24} textAlign='center'>
            Your cart is empty
          </Box>
        )}
      </Box>
      <Divider fullWidth />
      {products.length > 0 && (
        <>
          <Box
            display='flex'
            pt={4}
            px={2}
            color='#666'
            justifyContent='space-between'
          >
            <Box fontWeight={700} fontSize={24}>
              Subtotal
            </Box>
            <Box fontWeight={700} fontSize={24}>
              &#x20b9;{getTotal()}
            </Box>
          </Box>
          <Box px={2} pb={2}>
            Shipping & taxes calculated at checkout
          </Box>

          <Box px={2} pb={3}>
            <TextField
              label='Your Remark'
              variant='outlined'
              value={remark}
              fullWidth
              placeholder='Anything you want to add along with delivery ?'
              onChange={(e) => setRemark(e.target.value)}
            />
          </Box>

          <Box>
            <CommonButton
              rounded
              loading={false}
              style={{ width: "100%", fontSize: 20, borderRadius: 28 }}
              startIcon={<ShoppingCartIcon />}
              onClick={checkoutOrders}
            >
              Proceed to Buy
            </CommonButton>
          </Box>
        </>
      )}

      <Box textAlign='center' p={2}>
        <Link to='/' className={classes.link}>
          Continue Shopping
        </Link>
      </Box>
    </Box>
  );
};

export default Cart;
