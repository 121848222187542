import React, { useState } from "react";
import CommonButton from "../../Common/CommonButton";
import classes from "./styles.module.css";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { Box, CircularProgress, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { imageUrl } from "../../../helpers/common";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../../actions/snackbar";
import moment from "moment";
import { getCouponDiscount } from "../../../api/coupons";

const ApplyCoupons = ({ appliedCouponValue, setAppliedCouponValue }) => {
  const [openModal, setOpenModal] = useState(false);
  const [couponValue, setCouponValue] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleClose = () => {
    setOpenModal(false);
    setErrorMessage(false);
  };
  const dispatch = useDispatch();

  const couponList = useSelector((state) => state.coupons.data);
  const cartProducts = useSelector((state) => state.cart.data);

  const cartDiscount = localStorage.getItem("hs_coupon_discount");
  const parsedCartDiscount = JSON.parse(cartDiscount);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "600px",
    height: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 8,
    overflow: "hidden",
  };

  const handleApplyCoupon = async (couponCode) => {
    const code = couponCode || couponValue;

    if (!code) {
      setErrorMessage("Please enter coupon code!");
      return;
    }

    const items = cartProducts.map((d) => {
      return {
        item_id: d?.id,
        quantity: parseInt(d?.qty),
      };
    });

    // checking is coupon code already applied
    if (
      appliedCouponValue &&
      appliedCouponValue?.toLocaleLowerCase() === code?.toLocaleLowerCase()
    ) {
      setErrorMessage("Coupon is already applied.");
      return;
    }

    const payload = {
      items: items,
      currency_code: "INR",
      coupon_code: code,
    };

    function checkCouponApply(items) {
      let totalDiscount = 0;

      for (const item of items) {
        if (item.discount > 0) {
          totalDiscount += item.discount;
        }
      }

      if (totalDiscount === 0) {
        throw new Error(
          "Sorry! This coupon is not applicable to any of the selected products."
        );
      }
    }

    try {
      setLoading(true);
      const response = await getCouponDiscount(payload);

      if (response?.data) {
        await checkCouponApply(response?.data);

        const couponDataResponse = {
          applied_coupon_code: code,
          data: response?.data,
        };

        localStorage.setItem(
          "hs_coupon_discount",
          JSON.stringify(couponDataResponse)
        );

        dispatch(showSuccessSnackbar("Coupon applied successfully."));
        setAppliedCouponValue(code);
        setOpenModal(false);
      }
    } catch (error) {
      dispatch(
        showErrorSnackbar(error?.response?.data?.message || error?.message)
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClearCoupon = () => {
    setAppliedCouponValue("");
    localStorage.removeItem("hs_coupon_discount");
  };

  return (
    <div className={classes.main}>
      <div className={classes.left_col}>
        <LocalOfferIcon className={classes.labelIcon} />
        {appliedCouponValue ? (
          <h4 className={classes.applied}>
            Coupon "{appliedCouponValue}" applied
          </h4>
        ) : (
          <h4>Apply Coupons</h4>
        )}
      </div>

      {appliedCouponValue ? (
        <ClearIcon className={classes.closeIcon} onClick={handleClearCoupon} />
      ) : (
        <CommonButton
          onClick={() => setOpenModal(true)}
          variant='contained'
          rounded
        >
          APPLY
        </CommonButton>
      )}

      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          {loading && (
            <div className={classes.loader}>
              <CircularProgress size={26} color='inherit' />
            </div>
          )}

          <div className={classes.modal_header}>
            <h3>Apply Coupon</h3>

            <ClearIcon className={classes.closeIcon} onClick={handleClose} />
          </div>

          <div className={classes.modal_body}>
            <div className={classes.input_sec}>
              <input
                type='text'
                className={classes.modal_input}
                placeholder='Enter coupon code'
                onChange={(e) => setCouponValue(e.target.value)}
                value={couponValue}
              />
              <button
                className={classes.input_button}  
                onClick={() => handleApplyCoupon()}
              >
                Apply
              </button>
            </div>
            {errorMessage && <p className={classes.error}>{errorMessage}</p>}

            <div className={classes.scrollView}>
              {couponList?.map((item) => (
                <div className={classes.coupon_item} key={item?.id}>
                  <div className={classes.coupon_image}>
                    <img
                      src={imageUrl(item?.image, "coupons", "120x120-")}
                      alt=''
                    />
                  </div>

                  <div className={classes.coupon_info}>
                    <div
                      className={`${classes.coupon_code} ${classes[
                        item?.coupon_code ===
                          parsedCartDiscount?.applied_coupon_code
                          ? "active_coupon_code"
                          : ""
                      ]
                        }`}
                      title='Apply coupon'
                      onClick={() => handleApplyCoupon(item?.coupon_code)}
                    >
                      {item?.coupon_code}
                    </div>
                    {item?.coupon_code ===
                      parsedCartDiscount?.applied_coupon_code && (
                        <span className={classes.appliedMark}>
                          <CheckIcon className={classes.checkIcon} /> Applied
                        </span>
                      )}

                    <div className={classes.title}>{item?.title}</div>
                    <div className={classes.description}>
                      {item?.description}
                    </div>

                    <div className={classes.description}>
                      Expires on:{" "}
                      {moment(item?.end_date).format("Do MMM, YYYY")}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ApplyCoupons;
