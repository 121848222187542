import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  GridList,
  GridListTile,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/KeyboardArrowRight";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useEffect, useState } from "react";
import ReactImageMagnify from "react-image-magnify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProductList, getReviews } from "../../../actions/products";
import { getSettingsInfo } from "../../../actions/settings";
import { getItemList } from "../../../api/items";
import { data_url, imageUrl } from "../../../helpers/common";
import "../../../index.css";
import AddToCartButton from "../../Common/AddToCartButton";
import MetaTags from "../../Common/MetaTags";
import ProductRating from "./ProductRating";
import SingleProductDetailMeta from "./SingleProductDetailMeta";
import useStyles from "./styles";
import VariantColors from "./VariantColors";
import VariantSizes from "./VariantSizes";
import SimilarProducts from "./SimilarProducts";
import ListingLayout from "../ListingLayout";
import RouteInfo from "../../Common/RouteInfo";

const getFinalPrice = (product) => {
  // cut mrp and selling price and show only offer price
  if (parseFloat(product.offer_price) > 0) {
    return (
      <Box
        alignItems='center'
        justifyContent='space-between'
        width='100%'
      >
        <Box
          justifyContent='space-between'
          width='100%'
          pt={1}
          display={"flex"}
        >
          <Box
            fontSize={18}
            color='#666'
            style={{ textDecoration: "line-through", marginRight: 30 }}

          >
            MRP: &#x20b9;{product.mrp}
          </Box>
          <Box
            fontSize={18}
            color='#666'
            style={{ textDecoration: "line-through" }}
          >
            Selling Price: &#x20b9;{product.selling_price}
          </Box>
        </Box>
        <Box fontSize={22} pt={1} style={{ 'color': 'green' }}>
          Offer Price: &#x20b9;{product.offer_price}
        </Box>
      </Box >
    );
  }

  if (parseFloat(product.selling_price) > 0) {
    return (
      <Box
        alignItems='center'
        justifyContent='space-between'
        width='100%'
        mt={1}
      >
        <Box
          fontSize={20}
          color='#666'
          style={{ textDecoration: "line-through" }}
        >
          MRP: &#x20b9;{product.mrp}
        </Box>
        <Box fontSize={22} style={{ 'color': 'green' }}>
          Selling Price: &#x20b9;{product.selling_price}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      alignItems='center'
      justifyContent='space-between'
      width='100%'
      mt={1}
    >
      <Box fontSize={22} style={{ 'color': 'green' }}>
        Price: &#x20b9;{product.mrp}
      </Box>
    </Box>
  );
}

const SingleProduct = () => {
  let { productId } = useParams();

  const [product, setProduct] = useState({});
  const [routeArray, setRouteArr] = useState([]);

  const [preventScrollTop, setPreventScrollTop] = useState(false)

  const varientLength = product.media?.split(",").length;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [currIndex, setIndex] = useState(0);
  const customer = useSelector((state) => state.auth.profile);
  const cartData = useSelector((state) => state.cart.data);

  useEffect(() => {
    const resize = () => {
      // Add any custom logic you want to execute during resize here

      const width = window.innerWidth;

      if (width < 500) {
        document.body.classList.add("add-space");
      } else {
        document.body.classList.remove("add-space");
      }
    };

    resize()

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
      document.body.classList.remove("add-space");
    };
  }, []);

  useEffect(() => {
    if (!preventScrollTop && productId) {
      window.scrollTo({
        top: 100,
        behavior: "smooth",
      });
    }

  }, [preventScrollTop, productId])

  useEffect(() => {
    if (productId) {
      getItemList(`products/${productId}`).then((json) => {
        setProduct(json.data);
      });
    }
  }, [productId]);

  useEffect(() => {
    dispatch(getSettingsInfo());
  }, [dispatch]);

  useEffect(() => {
    if (product?.id) {
      dispatch(getReviews(product?.id));
      setQuantity(product?.minimum_order_quantity);
    }
  }, [product, dispatch]);

  const leftArrowClicked = () => {
    if (currIndex > 0) {
      setIndex(currIndex - 1);
    } else {
      setIndex(0);
    }
  };

  const rightArrowClicked = () => {
    if (varientLength - 1 > currIndex) {
      setIndex(currIndex + 1);
    }
  };

  useEffect(() => {
    dispatch(
      getProductList(
        `ProductsSearch[category_id]=${product.category_id}&group_by=item_color`
      )
    );
  }, [dispatch, product]);

  // TODO: move out main image and its thumbnails to a separate component
  // TODO: use productUrl function everywhere to get the product url. Similary create for categoryUrl also.

  useEffect(() => {
    if (productId) {
      if (product) {
        setProduct(product);
      }

      const category = product?.category;

      let routes = [{ name: "Home", url: "/" }];

      if (category) {
        routes.push({
          name: category?.name,
          url: `/category/${category?.id}/1/${category?.name}`,
        });
      }

      if (product) {
        routes.push({
          name: product?.name,
          url: `/product/${product?.id}/1/${product?.url_slug}`,
        });
      }

      setRouteArr(routes);
    }
  }, [productId, product]);

  if (!product.id) {
    return (
      <>
        <RouteInfo paths={routeArray} />
        <ListingLayout>
          <CircularProgress className={classes.loader} />
        </ListingLayout>
      </>
    );
  }

  return (
    <>
      <RouteInfo paths={routeArray} />

      <ListingLayout>
        <Box className={classes.detailsMainWrapper}>
          {product?.name ? (
            <MetaTags
              module='products'
              title={product?.name}
              description={product?.description || product?.highlights}
              keywords={product?.search_tags}
              image={
                data_url +
                "/products/" +
                product?.id +
                "/" +
                product?.main_image
              }
            ></MetaTags>
          ) : null}

          {product?.name && <SingleProductDetailMeta product={product} />}

          <Grid container spacing={3}>
            <Grid item lg={6}>
              <Box mb={3} position='relative' className={classes.mainImgBox}>
                {varientLength > 1 ? (
                  <div style={{ width: "100%" }}>
                    <button
                      className={`carousel-btn left ${currIndex === 0 ? "disabled" : null
                        }`}
                      onClick={leftArrowClicked}
                    >
                      <img
                        src={require("../../../assets/images/left-arrow.png")}
                        alt='left'
                      />
                    </button>
                    <button
                      className={`carousel-btn right ${varientLength - 1 === currIndex ? "disabled" : null
                        }`}
                      onClick={rightArrowClicked}
                    >
                      <img
                        src={require("../../../assets/images/right-arrow.png")}
                        alt='right'
                      />
                    </button>
                  </div>
                ) : null}
                {product.media ? (
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: product?.name,
                        isFluidWidth: true,
                        src: imageUrl(
                          product.media?.split(",")?.[currIndex],
                          "products/" + product.id,
                          "600x720-"
                        ),
                      },
                      largeImage: {
                        src: imageUrl(
                          product.media?.split(",")?.[currIndex],
                          "products/" + product.id,
                          "original"
                        ),
                        width: 900,
                        height: 1080,
                      },
                      style: { zIndex: 99 },
                      isHintEnabled: true,
                    }}
                  />
                ) : (
                  <Skeleton
                    className={classes.skeleton}
                    variant='rect'
                    width='100%'
                    height={400}
                  />
                )}
              </Box>
              {varientLength > 1 ? (
                <GridList
                  cellHeight={80}
                  spacing={3}
                  className={`productGridlist ${classes.gridList}`}
                  cols={5}
                >
                  {product.media?.split(",")?.map((tile, index) => (
                    <GridListTile
                      key={tile}
                      cols={1}
                      className={classes.gridListTile}
                    >
                      <img
                        src={imageUrl(tile, "products/" + product.id)}
                        style={{ objectFit: "cover" }}
                        alt={product?.name}
                        onClick={() => setIndex(index)}
                      />
                    </GridListTile>
                  ))}
                </GridList>
              ) : null}

              {product.media?.split(",")?.map((tile, index) => (
                <img
                  key={index}
                  style={{ visibility: "hidden" }}
                  width={1}
                  src={imageUrl(tile, "products/" + product.id, "600x720-")}
                  alt={tile.title}
                />
              ))}

              <VariantColors product={product} setPreventScrollTop={setPreventScrollTop} />
              <VariantSizes product={product} setPreventScrollTop={setPreventScrollTop} />
            </Grid>
            <Grid item lg={6}>
              <Box
                fontSize={32}
                fontWeight={700}
                style={{ textTransform: "capitalize" }}
              >
                {product?.name}
              </Box>
              {product.sku ? (
                <Box fontSize={22} my={1}>{`SKU: ${product.sku}`}</Box>
              ) : (
                <Skeleton
                  className={classes.skeleton}
                  variant='rect'
                  width={210}
                  height={30}
                />
              )}
              <Box style={{ display: "flex", alignItems: "center" }}>

                {/* {product.selling_price ? (
                  <Box
                    fontWeight={700}
                    fontSize={25}
                    color='#E16434'
                  >{`Rs. ${product.selling_price}`}</Box>
                ) : (
                  <Skeleton
                    className={classes.skeleton}
                    variant='rect'
                    width={210}
                    height={30}
                  />
                )} */}

                {/* {product.mrp !== product.selling_price && product.mrp !== 0 ? (
                  <Box
                    fontSize={18}
                    style={{ textDecoration: "line-through", marginLeft: 10 }}
                    color='#666'
                  >{`MRP: Rs. ${product.mrp}`}</Box>
                ) : null} */}

                <div>
                  {getFinalPrice(product)}
                </div>

              </Box>

              <Box className={classes.additional_fields_text}>
                Color: {product?.item_color}
              </Box>
              <Box className={classes.additional_fields_text}>
                Size: {product?.item_size}{" "}
              </Box>

              <Box
                my={2}
                fontFamily={"Product Sans"}
                dangerouslySetInnerHTML={{ __html: product.description }}
              />

              <Box className={classes.buttonContainer}>
                <TextField
                  label=''
                  variant='outlined'
                  value={quantity}
                  type='number'
                  onChange={(e) => setQuantity(e.target.value)}
                  style={{ marginRight: 12 }}
                  classes={{ root: classes.zipInput }}
                  className={classes.inputBtn}
                  inputProps={{ min: 1, max: parseInt(product.sku, 10) }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>Qty:</InputAdornment>
                    ),
                  }}
                />

                <AddToCartButton
                  cartData={cartData}
                  customer={customer}
                  product={product}
                  navigate={navigate}
                  quantity={quantity}
                />
              </Box>

              {product.highlights?.trim() !== "" && (
                <>
                  <Box fontSize={22} fontWeight={700} mt={3}>
                    Product Highlights
                  </Box>
                  {product.highlights?.split("\n").map((highlight) => (
                    <Box
                      key={highlight}
                      display='flex'
                      my={2}
                      alignItems='center'
                      fontFamily={"Product Sans"}
                    >
                      <ArrowRightAltIcon
                        style={{
                          borderRadius: 15,
                          color: "#fff",
                          background: "#E16434",
                          fontSize: 17,
                        }}
                      />
                      <Box ml={1} fontWeight={700} fontSize={15}>
                        {highlight}
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </Grid>
          </Grid>

          {product.attributes?.trim() !== "" && (
            <>
              <Box my={2} mt={4} fontSize={22} fontWeight={700}>
                Product attributes
              </Box>
              <Box>
                {product.attributes?.split("\n").map((tag) => (
                  <Chip key={tag} className={classes.chip} label={tag}></Chip>
                ))}
              </Box>
            </>
          )}

          <ProductRating product={product} />

          <SimilarProducts product={product} />
        </Box>
      </ListingLayout>
    </>
  );
};

export default SingleProduct;
