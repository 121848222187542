import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames/bind';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { productUrl } from '../../../helpers/common';
import useStyles from './styles';
import "../../../index.css";
import { getItemList } from '../../../api/items';

const VariantSizes = ({ product, setPreventScrollTop }) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [productVariants, setProductVariants] = useState([]);

    useEffect(() => {
        setProductVariants([]);
        if (product.id) {
            getItemList(`products?ProductsSearch[variants_of]=${product.id}&ProductsSearch[item_color]=${product.item_color}&sort=id`).then(
                (json) => {
                    const availableProducts = json.data.filter((variant) => variant.available_stock > 0);
                    setProductVariants(availableProducts);
                }
            );
        }
    }, [dispatch, product.id, product.item_color]);

    return (
        <Box>
            <Typography variant="h5" className={classes.productName}>
                Sizes
            </Typography>
            <Box className={classes.sizeSec}>
                {productVariants.map((variant, index) => (
                    <Box key={index} className={classNames(classes.sizeBox, { [classes.activeSize]: variant.id === product.id })} onClick={() => {
                        navigate(productUrl(variant));
                        setPreventScrollTop(true)
                        }}>
                        {variant.item_size}
                    </Box>
                ))}
            </Box>
        </Box >
    );
}

export default VariantSizes;
