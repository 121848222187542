import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import useStyles from "./styles";
import Sidebar from "./Sidebar";
import { useDispatch } from "react-redux";
import { getCategory } from "../../actions/categories";

const ListingLayout = ({ ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  return (
      <Grid
        container
        style={{
          maxWidth: 1170,
          margin: "auto",
          padding: 15,
          marginBottom: 70,
        }}
      >
        <Grid item md={3} xs={12} className={classes.leftSidebar}>
          <Sidebar {...props} />
        </Grid>

        <Grid item md={9} xs={12} className={classes.mainGrid}>
          {props.children}
        </Grid>
      </Grid>
  );
};

export default ListingLayout;
