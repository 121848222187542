import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@material-ui/core";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import CommonButton from "../Common/CommonButton";

export default function SingleOrderCard({
  classes,
  order: { order_id, order_date, total_amount, coupon_code, total_items },
}) {
  const navigate = useNavigate();

  const viewOrder = () => {
    navigate(`/my-orders/${order_id}`);
  };

  return (
    <>
      <Card className={classes.cardRoot} onClick={viewOrder}>
        <CardHeader
          avatar={
            <Avatar
              src={require("../../assets/images/gift_icon.png")}
              aria-label='item'
              className={classes.avatar}
            />
          }
          title={
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
            >
              <Box>{`${total_items} ${total_items > 1 ? "items" : "item"
                }`}</Box>
              <Box>{`Rs. ${total_amount}`}</Box>
            </Box>
          }
          subheader={`Order placed at ${moment(order_date).format(
            "Do MMM, YYYY"
          )}`}
          classes={{ title: classes.title, subheader: classes.subheader }}
        />
        <CardContent>
          <Box display='flex' justifyContent='space-between'>
            <Box fontWeight={700}>{`Total Price: ${total_amount}`}</Box>
            <Box display='flex' alignItems='center' color='#666'>

              <Box
                fontStyle='italic'
                fontWeight={500}
                mr={1}
                color={!coupon_code ? 'grey' : 'green'}
              >{`Coupon: ${!coupon_code ? "Not Applied" : coupon_code}`}</Box>

              <LocalOfferIcon style={{ fontSize: 16, color: "green" }} />
            </Box>
          </Box>
        </CardContent>
        <CardActions style={{ justifyContent: "flex-end" }}>
          Order ID: <strong style={{ marginLeft: 5 }}>{order_id}</strong>
          <CommonButton rounded width={150}>
            View Details
          </CommonButton>
        </CardActions>
      </Card>
    </>
  );
}
