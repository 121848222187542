/* eslint-disable linebreak-style */
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import Home from "./components/Home/index";

import { authProtectedRoutes, commonRoutes, publicRoutes } from "./routes";
import NotFoundPage from "./components/NotFoundPage";
import PrivateRoute from "./components/HOC/PrivateRoute";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<App />} exact>
          <Route index element={<Home />} />

          {commonRoutes.map((route) => (
            <Route
              path={route.path}
              element={route.element}
              key={route.path}
            />
          ))}

          {publicRoutes.map((route) => (
            <Route
              path={route.path}
              element={route.element}
              key={route.path}
            />
          ))}

          {authProtectedRoutes.map((route) => (
            <Route
              exact
              key={route.path}
              path={route.path}
              element={
                <PrivateRoute>
                  {route.element}
                </PrivateRoute>
              }
            />
          ))}

          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
