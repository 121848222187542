import { GET_PRODUCTS_FAILURE, GET_PRODUCTS_SUCCESS, GET_REVIEWS_SUCCESS, SUBMIT_REVIEW_SUCCESS } from '../constants/actionTypes';

const INITIAL_STATE = {
  list: [],
  featured: [],
  selling: [],
  offerProducts: [],
  flashSale: [],
  reviews: [],
  similar: [],
  error: null,
  pageCount: 0,
  itemCount: 0
};

export default function products(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      if (action.payload.productLabels === 'Hot') {
        return {
          ...state,
          selling: action.payload.data,
          pageCount: action.payload.headers['x-pagination-page-count'],
          itemCount: action.payload.headers['x-pagination-total-count']
        };
      } else if (action.payload.productLabels === 'Featured') {
        return {
          ...state,
          featured: action.payload.data,
          pageCount: action.payload.headers['x-pagination-page-count'],
          itemCount: action.payload.headers['x-pagination-total-count']
        };
      } else if (action.payload.productLabels === 'Offer') {
        return {
          ...state,
          offerProducts: action.payload.data,
          pageCount: action.payload.headers['x-pagination-page-count'],
          itemCount: action.payload.headers['x-pagination-total-count']
        };
      } else if (action.payload.productLabels === 'Flash Sale') {
        return {
          ...state,
          flashSale: action.payload.data,
          pageCount: action.payload.headers['x-pagination-page-count'],
          itemCount: action.payload.headers['x-pagination-total-count']
        };
      } else if (action.payload.similar_of) {
        return {
          ...state,
          similar: action.payload.data,
          pageCount: action.payload.headers['x-pagination-page-count'],
          itemCount: action.payload.headers['x-pagination-total-count']
        };
      } else {
        return {
          ...state,
          list: action.payload.data,
          pageCount: action.payload.headers['x-pagination-page-count'],
          itemCount: action.payload.headers['x-pagination-total-count']
        };
      }
    case GET_PRODUCTS_FAILURE:
      return {
        ...state, error: action.payload.error
      }
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.payload.data
      }
    case SUBMIT_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: [...state.reviews.reviews, action.payload]
      }
    default:
      return state;
  }
}
