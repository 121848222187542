import { Box, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";

const CategoryCard = ({ image, title, width = 300, square = false }) => {
  const classes = useStyles();
  const matchesLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const boxWidth = matchesLg
    ? width && width <= 240
      ? width
      : 240
    : width && width <= '100%'
    ? width
    : '100%';

  return (
    <Box
      position='relative'
      width={boxWidth}
      height={square ? boxWidth : "auto"}
      overflow='hidden'
      className={classes.contentRoot}
    >
      <img src={image} alt={title} className={classes.categoryImage} />
      <Box
        position='absolute'
        bottom={0}
        padding={1.3}
        textAlign='center'
        color='#fff'
        className={classes.overlayText}
      >
        <Typography variant='h6'>{title}</Typography>
      </Box>
    </Box>
  );
};

export default CategoryCard;
