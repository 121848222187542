import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  resendBlock: {
    position: 'relative',
    width: '100%',
    textAlign: 'center'
  },
  circularProgress: {
    marginTop: 20,
  }
}));

export default useStyles;
