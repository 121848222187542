import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  resendBlock: {
    position: "relative",
    width: "100%",
    textAlign: "center",
  },
  circularProgress: {
    marginTop: 20,
  },
  otpInputStyle: {
    width: '40px !important',
    height: 40,
    margin: "0 8px",
    "@media (max-width: 768px)": {
      width: '35px !important',
      height: '35px !important',
    },
  },
  para: {
    textAlign: "center",
    fontSize: 14,
    marginTop: 8,
    marginBottom: 16,
  }
}));

export default useStyles;
