import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import ShippingProductDisplay from "../Shipping/ShippingProductDisplay";
import CheckoutDetailsDisplay from "./CheckoutDetailsDisplay";
import { useNavigate, useLocation } from "react-router-dom";
import MetaTags from "../Common/MetaTags";
import useStyles from "./styles";
import PaymentSec from "./PaymentSec";
import useViewportSize from "../../helpers/useViewportSize";

export default function Checkout() {
  const location = useLocation();
  const data = location?.state?.order;
  const navigate = useNavigate();
  const classes = useStyles();

  const { isMobile } = useViewportSize();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    if (!location.state || !data) {
      navigate("/shipping");
    }
  }, [data, location.state, navigate]);


  if (!location.state || !data) {
    navigate("/cart");
    return false;
  }

  return (
    <Box
      maxWidth={1200}
      p={3}
      overflow='hidden'
      width='100%'
      className={classes.mainSec}
    >
      <MetaTags title='Checkout Details' description='' keywords=''></MetaTags>

      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Typography
            variant='h5'
            color='textSecondary'
            className={classes.mainTitle}
          >
            Checkout Details
          </Typography>
          <CheckoutDetailsDisplay
            customer={data?.customer}
            isMobile={isMobile}
          />
        </Grid>

        <ShippingProductDisplay
          deliveryCharges={data?.order?.delivery_charges}
          orderData={data?.order}
          pageSource='checkout'
        >
          {/* This payment sec will show only on mobile device */}
          {isMobile && (
            <div className={classes.mobilePaymentSec}>
              <Box fontWeight={700} fontSize={20} mt={3} mb={-1.2}>
                Payment Options
              </Box>
              <PaymentSec />
            </div>
          )}
        </ShippingProductDisplay>
      </Grid>
    </Box>
  );
}
