import React from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { showErrorSnackbar } from "../../../actions/snackbar";
import { authKey_local_storage_key } from "../../../helpers/common";

export default function PrivateRoute({ children }) {
  const auth = localStorage.getItem(authKey_local_storage_key);
  const dispatch = useDispatch();
  if (!auth) {
    dispatch(showErrorSnackbar("You must be login to proceed!"));
  }
  return auth ? children : <Navigate to="/login" />;
}
