import { createAction } from 'redux-actions';
import { SHOW_SNACKBAR_SUCCESS, SHOW_SNACKBAR_ERROR, HIDE_SNACKBAR } from '../constants/actionTypes';

const showSnackbarSuccess = createAction(SHOW_SNACKBAR_SUCCESS);
const showSnackbarError = createAction(SHOW_SNACKBAR_ERROR);
const hideSnackbar = createAction(HIDE_SNACKBAR);

export function showSuccessSnackbar(message, action = null) {
  return dispatch => dispatch(showSnackbarSuccess({ message, action }));
}

export function showErrorSnackbar(message = 'Request failed', action = null) {
  return dispatch => dispatch(showSnackbarError({ message, action }));
}

export function hideSnackbarMessage() {
  return dispatch => dispatch(hideSnackbar());
}
