import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import SingleOrderCard from "./SingleOrderCard";

import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrder } from "../../actions/orders";
import MetaTags from "../Common/MetaTags";

export default function OrderHistory(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  const frontendData = useSelector((state) => state.contents.frontendData);
  const contacts = frontendData
    .find((data) => data.key === "company_contact_number")
    ?.value.trim()
    .split("\n");

  const whatsappUrl = contacts?.length ? contacts[0] : null;

  useEffect(() => {
    setLoading(true);
    
    dispatch(getAllOrder()).then((response) => {
      setOrders(response.payload.data);
      setLoading(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }, [dispatch]);

  return (
    <Box p={3}>
      <MetaTags title='My Orders' description='' keywords=''></MetaTags>

      <Box m='auto' maxWidth={1200} minHeight={300}>
        <Box fontSize={22} fontWeight={600} color='#666' mb={3}>
          Order History
        </Box>

        {loading ? (
          <CircularProgress className={classes.loader} />
        ) : (
          <Grid container spacing={2} justifyContent='center'>
            {orders?.length === 0 ? (
              <Box
                fontSize={20}
                color='#666'
                style={{ textAlign: "center", marginTop: 100 }}
              >
                No Orders Found!
              </Box>
            ) : null}
            {[...orders, ...orders, ...orders, ...orders, ]?.map((order) => (
              <Grid item key={order.id} lg={4} md={6} xs={12}>
                <SingleOrderCard classes={classes} order={order} />
              </Grid>
            ))}
          </Grid>
        )}
        <br />
        <br />
        <br />
        <Box textAlign={"center"}>
          For return or exchange, you can contact us using WhatsApp by clicking{" "}
          <a target='_blank' href={whatsappUrl} rel='noreferrer'>
            here
          </a>
          .
        </Box>
      </Box>
    </Box>
  );
}
