class Helper {
  static getFinalProductList = (cartData, currentOrder) => {
    const productList = [];

    // pushing those products to the cart which is not in this order.
    if (cartData) {
      cartData.forEach((item) => {
        const existInOrder = currentOrder?.find(
          (order) => order?.product_id === item?.id
        );

        if (!existInOrder) {
          productList.push(item);
        }
      });
    }

    // checking if the order has already in the cart then only updating quantity otherwise pushing old order item.
    if (currentOrder) {
      currentOrder.forEach((order) => {
        const isExist = cartData?.find((item) => item.id === order?.product_id);

        if (isExist) {
          productList.push({
            ...order.product,
            qty: JSON.parse(isExist?.qty) + 1,
          });
        } else {
          productList.push({ ...order.product, qty: 1 });
        }
      });
    }

    return productList;
  };
}

export default Helper;
