import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dash: { height: 5, width: 60, backgroundColor: '#E16434' },
  categoryList: {
    "@media (max-width: 991px)":{
      display: 'none',
    }
  },
  bottomBar: {
    // position: 'absolute',
    bottom: 20,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#eee',
    padding: '10px 20px',
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    fontFamily: 'Product Sans',
    color: '#555',
    fontWeight: 700,
    marginTop: 25,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      bottom: -40
    }
  },
  bottomCarousel: {
    backgroundImage: `url(${require('../../assets/images/featured-product-bg.jpg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  mainGrid: {
    position: 'relative',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit'
  },
  pagination: {
    '& button': { fontFamily: 'Product Sans', fontWeight: 700, color: '#555' }
  }
}))

export default useStyles;
