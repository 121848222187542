import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  mainSec: {
    margin: "48px auto",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
      paddingBottom: 80,
    },
    "@media(max-width: 465px)": {
      paddingBottom: 140,
    },
  },
  shippingField: {
    margin: "16px 0",
  },
  regions: {
    fontFamily: "Product Sans",
    height: 56,
    marginRight: 32,
    width: "100%",
    color: "#666",
    fontSize: 16,
    borderRadius: 5,
    borderColor: "#c0c0c0",
    background: "none",
  },
  items: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      borderLeft: "1px solid #aaa",
    },
    [theme.breakpoints.down("md")]: {
      borderTop: "1px solid #aaa",
    },
  },
  images: {
    width: "100%",
    objectFit: "cover",
    border: "1px solid #aaa",
    borderRadius: 6,
  },
  removeBtn: {
    cursor: "pointer",
    color: "#E16434",
    marginLeft: 5,
  },
  appliedCoupon: {
    color: "#1eab5c",
    textTransform: "uppercase",
    fontWeight: 600,
  },
  btnContainer: {
    "@media(max-width: 960px)": {
      position: "absolute",
      bottom: 25,
      left: 0,
      right: 0,
      width: "100%",
      padding: '0 25px',
    },
    "@media(max-width: 465px)": {
      justifyContent: "center",
    },
  },
  commonBtn: {
    width: 200,
    "@media(max-width: 465px)": {
      width: 270,
      margin: "auto",
    },
  },
  prodBox: {
    padding: "8px 0",
    display: "flex",
  },
  prodImgBox: {
    width: 80,
    borderRadius: 5,
    border: "1px solid transparent",
    "@media(max-width: 465px)": {
      width: 55,
    },
  },
  prodName: {
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0.5,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    '-webkitLineClamp': 3,
    '-webkitBoxOrient': 'vertical',
    textOverflow: 'ellipsis',
    "@media(max-width: 465px)": {
      fontSize: 15,
    },
  },
  prodAmount: {
    fontSize: 24,
    fontWeight: '700',
    margin: '0 10px',
    "@media(max-width: 465px)": {
      fontSize: 18,
    },
  },
  pill: {
    background: "#eee",
    borderRadius: 20,
    padding: "4px 10px",
    display: "flex",
    alignItems: "center",
    marginBottom: 0,
    fontSize: 13,
    fontWeight: 600,
    color: '#353535',
    "@media(max-width: 500px)": {
      fontSize: 11,
    },
  },
}));

export default useStyles;
