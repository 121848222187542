import React, { useCallback } from 'react';

import './style.css';

import Paypal from './gateways/Paypal';
import Razorpay from './gateways/Razorpay';
import Paytm from './gateways/Paytm';
import Cod from './gateways/Cod';
import { useLocation, useNavigate } from "react-router-dom";
import { createPayment as createPaymentApi, verifyPayment } from '../../../api/orders';
import CcAvenue from './gateways/CcAvenue';
import { useDispatch } from 'react-redux';
import { CLEAR_CART } from '../../../constants/actionTypes';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

const PaymentButton = ({ currentGateway, onPaymentFailureHandler, onPaymentSuccessHandler, cartStorageKey }) => {

    const location = useLocation();
    const checkoutResponse = location?.state?.order;

    const navigate = useNavigate();
    const dispatch = useDispatch()

    const onPaymentSuccess = async (orderId, response) => {

        try {
            let verificationResponse = await verifyPayment({ order_id: orderId, payload: response });

            if (verificationResponse?.data?.payment_status === 'Failed') {
                throw new Error(verificationResponse?.data?.payment_verification_error_message);
            }

            if (onPaymentSuccessHandler) {
                onPaymentSuccessHandler(orderId);
            } else {
                if (cartStorageKey) {
                    localStorage.removeItem(cartStorageKey);
                }
                dispatch({type: CLEAR_CART})
                
                alert('Order Placed Successfully.');
                navigate("/my-orders", {replace: true});
            }

        } catch (e) {
            console.log(e);
            handleError({
                'reason': e?.message ?? 'Payment Failed. Please try again.'
            });
        }
    }

    const handleError = (error) => {
        if (onPaymentFailureHandler) {
            onPaymentFailureHandler(error);
        } else {
            alert(error.reason);
        }
    }

    const onPaymentFailed = (error) => {
        handleError(error);
    }

    const gateways = {
        paypal: Paypal,
        razorpay: Razorpay,
        paytm: Paytm,
        cod: Cod,
        ccavenue: CcAvenue,
    }


    const createPayment = useCallback(async () => {
        let data = {};
        try {
            let response = await createPaymentApi({
                "id": checkoutResponse?.order?.id,
                "payment_gateway": currentGateway?.internal_name,
                "payment_gateway_environment": currentGateway?.environment
            });
            data = response.data;
        } catch (e) {
            console.log(e);
        } finally {
        }
        return data;
    }, [checkoutResponse?.order?.id, currentGateway?.environment, currentGateway?.internal_name]);

    const getPaymentGatewayButton = (currentGateway) => {
        if (currentGateway?.internal_name) {
            const Component = gateways[currentGateway.internal_name];
            return <Component currentGateway={currentGateway} createPayment={createPayment} checkoutResponse={checkoutResponse} onPaymentSuccess={onPaymentSuccess} onPaymentFailed={onPaymentFailed} icon={<ShoppingCartIcon />}></Component>
        }
    }

    let button = getPaymentGatewayButton(currentGateway);

    return (
        <div id="payNowButtonContainer">
            {button}
        </div>
    )
}

export default PaymentButton;
