
import React, { useState, useCallback } from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import CommonButton from '../Common/CommonButton';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorSnackbar, showSuccessSnackbar } from '../../actions/snackbar';
import { verifyEmail } from '../../actions/customer';
import { LOGIN_SUCCESS } from '../../constants/actionTypes';
import useStyles from './styles';
import ResendOtp from '../ResendOtp';


export default function VerifyEmail() {
  
  const classes = useStyles();
  let email = null;
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const {state} = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');

  const handleFormSubmit = useCallback(
      async (e) => {
        e.preventDefault();

        if (!otp?.length) {
          dispatch(showErrorSnackbar("Please Enter Otp"));
          return false;
        }
        if (otp.length < 6) {
          dispatch(showErrorSnackbar("Invalid OTP Value."));
          return false;
        }
        setLoading(true);
        try {
          const response = await verifyEmail(email, otp);
          dispatch({ type: LOGIN_SUCCESS, payload: response.data });
          dispatch(showSuccessSnackbar("Logged in successfully."));
          navigate("/");
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
          dispatch(showErrorSnackbar('The OTP you provided is not valid or something went wrong.'));
        }
      },
      [email, navigate, otp, dispatch]
    );
  
  if (!state || !state.email) {
    navigate("/signup");
    return true;
  }

  email = state.email;

  return (
    <>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mb={8}>
        <Box fontSize={24} fontWeight={700} color="#666" mt={5} mb={2}>
          VERIFY OTP
        </Box>
        <Typography color="textSecondary" style={{ marginBottom: 20 }}>
          Enter the OTP sent to your email
          <Typography style={{ textAlign: 'center' }}>{state.email}</Typography>
        </Typography>
        <form onSubmit={handleFormSubmit}>
            <OtpInput
              value={otp}
              onChange={(otp) => setOtp(otp)}
              numInputs={6}
              separator={<span>-</span>}
              inputStyle={{ width: 40, height: 40, margin: '0 8px' }}
              focusStyle={{ borderColor: '#E16434' }}
            />
           <Box className={classes.resendBlock}>
              {resendLoading ? (<CircularProgress size={26} color="primary" className={classes.circularProgress} />):  (<ResendOtp maxTime={60} setResendLoading={setResendLoading} email={email}/>)}</Box>
            <Box display="flex" justifyContent="center" mt={3}>
              <CommonButton rounded width={150} loading={loading} variant="contained" color="primary" type="submit">VERIFY OTP</CommonButton>
            </Box>
        </form>
        <Box my={2}>
          <Box component="span" mr={1}>If you already have an account</Box>
          <Link to="/login">Login</Link>
        </Box>
      </Box>
    </>
  )
}
