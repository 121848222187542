import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../actions/customer";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";
import CommonButton from "../Common/CommonButton";
import { LOAD_CART_DATA } from "../../constants/actionTypes";
import { getLoginOtp } from "../../api/customer";
import "../../index.css";
import OtpInput from "react-otp-input";
import ResendOTP from "../ResendOtp";
import useStyles from "./styles";
import MetaTags from "../Common/MetaTags";
import { redirect_path_after_login } from "../../helpers/common";

export default function Login({ location }) {
  const lastPhoneNumber = localStorage.getItem("last_mobile_number");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  // const [formData, setFormData] = useState({});
  const [phoneNumber, setPhoneNumber] = useState(lastPhoneNumber);
  const [otpVerify, setOtpVerify] = useState(false);
  const [otp, setOtp] = useState("");
  const [resendLoading, setResendLoading] = useState(false);
  const redirectPathAfterLogin = localStorage.getItem(redirect_path_after_login)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const onChangeValue = (e) => {
    // max length of phone number is 10
    if (e.target.value.length <= 10) {
      setPhoneNumber(e.target.value);
    } else {
      setPhoneNumber(e.target.value.slice(0, 10));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!otp) {
      dispatch(showErrorSnackbar("Please enter a 6 digit OTP!"));
      return false;
    }

    setLoading(true);

    return dispatch(login({ mobile_number: phoneNumber, otp })).then((ack) => {
      setLoading(false);
      dispatch({ type: LOAD_CART_DATA });
      if (!ack) {
        dispatch(showErrorSnackbar("Invalid OTP or something went wrong!"));
        return;
      }
      // return !location.state?.from
      //   ? navigate.goBack()
      //   : navigate(location.state.from.pathname);

      const navigatePath =  redirectPathAfterLogin || '/'
      localStorage.removeItem(redirect_path_after_login)

      return navigate(navigatePath);
    });
  };

  const handleGetOtp = useCallback(
    async (e) => {
      e.preventDefault();
      if (!phoneNumber.length) {
        dispatch(showErrorSnackbar("Please enter a valid Mobile Number!"));
        return false;
      }

      if (phoneNumber.length < 10) {
        dispatch(showErrorSnackbar("Mobile number must be 10 digits"));
        return false;
      }

      localStorage.setItem("last_mobile_number", phoneNumber);
      setLoading(true);

      try {
        // sending an otp to the given mobile.
        let response = await getLoginOtp({
          mobile_number: phoneNumber,
        });
        if (
          !response?.data?.message === "Error in sending OTP. Please try again."
        ) {
          dispatch(
            showErrorSnackbar(
              "Could not send OTP due to technical reason. Please try again later!"
            )
          );
          setLoading(false);
          return false;
        } else {
          dispatch(showSuccessSnackbar("An OTP sent to your mobile number"));
          setOtpVerify(true);
          setLoading(false);
          setOtp("");
        }
      } catch (err) {
        setLoading(false);
        dispatch(
          showErrorSnackbar("error accured while sending otp try again!")
        );
      }
    },
    [dispatch, phoneNumber]
  );

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      mb={8}
      textAlign='center'
      px={2}
    >
      <MetaTags
        title={!otpVerify ? "LOGIN / SIGN UP" : "Verify OTP"}
        description=''
        keywords=''
      ></MetaTags>

      <Box className={classes.heading}>
        {!otpVerify ? "LOGIN / SIGN UP" : "Verify OTP"}
      </Box>

      {!otpVerify ? (
        <Typography
          component='span'
          color='textSecondary'
          style={{ marginBottom: 20 }}
        >
          Sign Up or Sign In to access your account and more!
        </Typography>
      ) : (
        <Typography
          component='span'
          color='textSecondary'
          style={{ marginBottom: 20 }}
        >
          Enter the OTP sent to your mobile number
          <Typography component='div' style={{ textAlign: "center" }}>
            {phoneNumber}
          </Typography>
        </Typography>
      )}

      {!otpVerify ? (
        <form onSubmit={handleGetOtp}>
          <Box width={480} className='input-sec'>
            <Grid container alignItems='center' direction='column'>
              <Box>
                <Box mr={1} mb={1}>
                  Your Number*
                </Box>
                <TextField
                  placeholder='Enter your mobile number'
                  variant='outlined'
                  type='number'
                  value={phoneNumber}
                  onChange={onChangeValue}
                  style={{ width: 320 }}
                />
              </Box>
            </Grid>
          </Box>

          <Box display='flex' justifyContent='center' mt={2}>
            <CommonButton rounded width={150} type='submit' loading={loading}>
              Get OTP
            </CommonButton>
          </Box>
        </form>
      ) : (
        <form onSubmit={onSubmit}>
          <Box width={480} className='input-sec'>
            <Grid container alignItems='center' direction='column'>
              <Box>
                <OtpInput
                  value={otp}
                  onChange={(otp) => setOtp(otp)}
                  numInputs={6}
                  separator={<span>-</span>}
                  inputStyle={{ width: 36, height: 36, margin: "0 8px" }}
                  focusStyle={{ borderColor: "#E16434" }}
                  isInputNum={true}
                />
              </Box>

              <Box className={classes.resendBlock}>
                {resendLoading ? (
                  <CircularProgress
                    size={26}
                    color='primary'
                    className={classes.circularProgress}
                  />
                ) : (
                  <ResendOTP
                    maxTime={60}
                    setResendLoading={setResendLoading}
                    mobile_number={phoneNumber}
                  />
                )}
              </Box>
            </Grid>
          </Box>

          <Box display='flex' justifyContent='center' mt={2}>
            <CommonButton rounded width={150} type='submit' loading={loading}>
              Submit OTP
            </CommonButton>
          </Box>

          <Box
            display='flex'
            justifyContent='center'
            mt={2}
            onClick={() => setOtpVerify(false)}
            style={{ cursor: "pointer" }}
          >
            Go to Login
          </Box>
        </form>
      )}
    </Box>
  );
}
