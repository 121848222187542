import React, { useEffect, useState, useCallback } from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/material.css";

import { signupUser, getErrorMsg } from "../../actions/customer";
import { showErrorSnackbar } from "../../actions/snackbar";
import CommonButton from "../Common/CommonButton";

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const profileError = useSelector((state) => state.auth.errors);

  const handleFieldChange = (e, fieldName) => {
    setFormData({ ...formData, [fieldName]: e.target.value });
  };

  useEffect(() => {
    if (profileError) dispatch(showErrorSnackbar(profileError));
  }, [dispatch, profileError]);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      // setFormErrors({});
      if (formData.password.length < 8) {
        // x: In which field we are setting? Check the login form error msg properly. formData is an object and not the string.
        dispatch(
          showErrorSnackbar("Password must be atleast 8 characters long!")
        );
        return false;
      }
      if (formData.password !== formData.passwordConfirm) {
        dispatch(showErrorSnackbar("Passwords doesn't match"));
        return false;
      }
      setLoading(true);
      try {
        await signupUser(formData);
        navigate("/verify-email", {
          state: { email: formData.email, password: formData.password },
        });
        setLoading(false);
      } catch (err) {
        console.log(err.response);
        setLoading(false);
        dispatch(showErrorSnackbar(getErrorMsg(err)));
      }
    },
    [formData, navigate, dispatch]
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        mb={8}
      >
        <Box fontSize={24} fontWeight={700} color="#666" mt={5} mb={2}>
          SIGN UP
        </Box>
        <form onSubmit={onSubmit}>
          <Box width={480}>
            <Grid container alignItems="center">
              <Grid item xs={3} className="input-lable">
                <Box mr={1}>Email Address*</Box>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  required
                  placeholder="Enter your email address"
                  variant="outlined"
                  type="email"
                  value={formData.email}
                  onChange={(e) => handleFieldChange(e, "email")}
                  style={{ width: 300 }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box width={480} mt={2} className="input-sec">
            <Grid container alignItems="center">
              <Grid item xs={3} className="input-lable">
                <Box mr={1}>Password*</Box>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  placeholder="Enter new password"
                  variant="outlined"
                  type="password"
                  value={formData.password}
                  inputProps={{ minLength: 6 }}
                  onChange={(e) => handleFieldChange(e, "password")}
                  style={{ width: 300 }}
                />
              </Grid>
            </Grid>
          </Box>

          <Box width={480} mt={2} mb={5} className="input-sec">
            <Grid container alignItems="center">
              <Grid item xs={3} className="input-lable">
                <Box mr={1}>Confirm Password*</Box>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  placeholder="Confirm new password"
                  variant="outlined"
                  type="password"
                  value={formData.passwordConfirm}
                  onChange={(e) => handleFieldChange(e, "passwordConfirm")}
                  helperText={
                    formData.passwordConfirm !== "" &&
                    formData.password !== formData.passwordConfirm &&
                    "The password does not match"
                  }
                  style={{ width: 300 }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box my={2} display="flex" justifyContent="center">
            <Box component="span" mr={1}>
              By clicking continue, you agree with our
            </Box>
            <Link to="/pages/privacy-policy">Privacy & Policy</Link>
          </Box>
          <Box display="flex" justifyContent="center">
            <CommonButton type="submit" color="primary" loading={loading}>
              Continue
            </CommonButton>
          </Box>
        </form>
        <Box my={2}>
          <Box component="span" mr={1}>
            If you already have an account
          </Box>
          <Link to="/login">Login</Link>
        </Box>
      </Box>
    </>
  );
}
