import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  scrollTopButton: {
    position: "fixed",
    bottom: 80,
    right: 30,
    width: 40,
    height: 40,
    borderRadius: "50%",
    background: '#E16434',
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    boxShadow: "0px 0px 15px rgba(0,0,0,0.3)",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    opacity: 0,
    transform: "scale(0)",
    visibility: "hidden",
    zIndex: 100,
    '@media (max-width: 500px)': {
      width: 35,
      height: 35,
      right: 20,
      display: 'none'
    }
  },
  scrollTopButtonActive: {
    opacity: 1,
    visibility: "visible",
    transform: "scale(1)",
  },
  icon: {
    color: '#fff',
    fontSize: 25,
  },
}));

export default useStyles;
