import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cartButton: {
    backgroundColor: "#E16434",
    borderRadius: 2,
    height: 50,
    width: 50,
    color: "#fff",
    "&:hover": {
      backgroundColor: "#FAA499",
    },
  },
  contentTop: {
    minHeight: 95,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width: 960px)": {
      minHeight: 'auto',
      marginBottom: 3
    },
  },
  productBoxTitleContainer: {
    width: "calc(100% - 55px)",
  },
  productBoxTitle: {
    maxWidth: "100%",
    marginTop: 10,
    textAlign: "left !important",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkitLineClamp": 2,
    "-webkitBoxOrient": "vertical",
  },
  imageBox: {
    height: "323px",
    overflow: "hidden",
    position: "relative",
    "@media (max-width: 1100px)": {
      minHeight: "280px",
      height: "auto",
    },
    "@media (max-width: 767px)": {
      minHeight: "220px",
      height: "auto",
    },
  },
  productImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "all ease-in-out 0.5s",
    "&:hover": {
      transition: "all ease-in-out 0.5s",
      transform: "scale(1.05)",
    },
  },
  defaultImage: {
    width: "100%",
    height: "100%",
    display: "block",
    objectFit: "cover",
    position: "absolute",
    top: 0,
  },
  hidden: {
    display: "none",
  },
  addCartContainer: {
    marginTop: 10,
    marginBottom: 10,
    "@media (max-width: 960px)": {
      marginTop: 7,
      marginBottom: 20,
    },
  },
  pill: {
    background: "#eee",
    borderRadius: 20,
    padding: "4px 10px",
    display: "flex",
    alignItems: "center",
    marginBottom: 4,
    fontSize: 13,
  },
}));

export default useStyles;
