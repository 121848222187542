import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonButton from "../Common/CommonButton";
import PaymentButton from "./PaymentGateways/PaymentButton";
import PaymentGatewayList from "./PaymentGateways/PaymentGatewayList";
import useStyles from "./styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const PaymentSec = () => {
  const [currentGateway, setCurrentGateway] = useState({});
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <div>
      <Box display='flex'>
        <PaymentGatewayList
          currentGateway={currentGateway}
          setCurrentGateway={setCurrentGateway}
        />
      </Box>

      <Box
        display='flex'
        justifyContent='space-between'
        alignItems={"center"}
        gridGap={15}
        mt={3}
        className={classes.btnContainer}
      >
          <CommonButton
            rounded
            onClick={() => navigate("/shipping")}
            style={{ width: 150, backgroundColor: "#1c5892", height: 40 }}
            startIcon={<ArrowBackIcon />}
          >
            Go Back
          </CommonButton>
          <PaymentButton
            currentGateway={currentGateway}
            cartStorageKey={"hsCartProducts"}
          ></PaymentButton>
      </Box>
    </div>
  );
};

export default PaymentSec;
