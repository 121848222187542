import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { imageUrl, productUrl } from '../../../helpers/common';
import useStyles from './styles';
import "../../../index.css";
import { getItemList } from '../../../api/items';

const VariantColors = ({ product, setPreventScrollTop}) => {

    const classes = useStyles();
    const navigate = useNavigate();
    const [productVariants, setProductVariants] = useState([]);

    useEffect(() => {
        setProductVariants([]);
        if (product.id) {
            getItemList(`products?ProductsSearch[variants_of]=${product.id}&group_by=item_color&sort=id`).then(
                (json) => {
                    const availableProducts = json.data.filter((variant) => variant.available_stock > 0);
                    setProductVariants(availableProducts);
                }
            );
        }
    }, [product.id]);

    return (
        <Box>
            <Typography variant="h5" className={classes.productName}>
                Colors
            </Typography>
            <Box className={classes.colorSec}>
                {productVariants.map((variant, index) => (
                    <Box textAlign="center" key={index}>
                        <Box key={index} className={classNames(classes.colorBox, { [classes.activeColor]: variant.item_color === product.item_color })}>
                            <img src={imageUrl(variant.main_image, 'products/' + variant.id, '100x120-')} style={{ objectFit: 'cover', height: 60, borderRadius: '50%' }} alt={variant.main_image.name} 
                            onClick={() => {
                                setPreventScrollTop(true)
                                navigate(productUrl(variant))
                                }} />
                            <br />
                        </Box>
                        {variant.item_color}
                    </Box>
                ))}
            </Box>
        </Box >
    );
}

export default VariantColors;
