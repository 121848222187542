import React, { useEffect } from "react";
import useStyles from "./styles";
import TopArrow from "@material-ui/icons/KeyboardArrowUp";

export default function ScrollTopButton() {
  const classes = useStyles();
  const [showScroll, setShowScroll] = React.useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const onScroll = () => {
      const isTop = window.scrollY > 400;
      if (isTop !== showScroll) {
        setShowScroll(isTop);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <div
      onClick={scrollToTop}
      className={`${classes.scrollTopButton} ${
        showScroll ? classes.scrollTopButtonActive : null
      }`}
    >
      <TopArrow className={classes.icon} />
    </div>
  );
}
