import { makeStyles } from "@material-ui/core";
import WhiteFadedBg from "../../assets/images/white-faded-bg.jpg";
import FeaturedCarouselBg from "../../assets/images/featured-product-bg.jpg";

export default makeStyles((theme) => ({
  root: { "& *": { fontFamily: "Product Sans" } },
  dash: { height: 5, width: 60, backgroundColor: "#E16434" },
  imageContainer: { display: "flex", "& > *": { margin: 12 } },
  gridList: { marginTop: 12, margin: "auto", maxWidth: 1120, width: "100%",
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px !important',
      marginTop: 15
    }
   },
  gridBox: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px !important',
    }
  },
  link: { textDecoration: "none", color: "inherit" },
  offeredProductBox: {
    background: `url(${WhiteFadedBg}) repeat`,
  },
  hotProductsBox: {
    background: `url(${WhiteFadedBg}) repeat`,
    paddingTop: "30px",
    paddingBottom: "30px",
  },
  catogoriesList: {
    backgroundColor: "#ebebeb",
    padding: "50px 0",
    "@media (max-width: 480px)": {
      padding: "25px 0",
    },
  },
  featuredCarousel: {
    backgroundImage: `url(${FeaturedCarouselBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
}));
