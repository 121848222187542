import { createAction } from 'redux-actions';
import { getItemList } from '../api/items';

import { GET_BANNERS_SUCCESS, GET_BANNERS_FAILURE } from '../constants/actionTypes';

const getBannerSuccess = createAction(GET_BANNERS_SUCCESS);
const getBannerFailure = createAction(GET_BANNERS_FAILURE);

export function getBanners(category) {
  return dispatch => getItemList('banners', { category })
    .then((json) => dispatch(getBannerSuccess({ ...json, bannerType: category })))
    .catch(error => dispatch(getBannerFailure(error)))
}
