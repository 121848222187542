import { Box } from '@material-ui/core';
import React from 'react';
import NotFoundImage from '../../assets/images/404.gif';

const NotFoundPage = () => {
  return (
    <Box display="flex" justifyContent="center" style={{ backgroundColor: '#eaeaea' }}>
      <Box width={700} pb={10}>
        <img src={NotFoundImage} alt="404" style={{ width: '100%' }} />
      </Box>
    </Box>
  )
}

export default NotFoundPage
