import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  categoryImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  overlayText: {
    zIndex: 99,
    background: "rgba(41,86,133,0.9)",
    width: "100%",
    "& > h6": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      [theme.breakpoints.down("xs")]: {
        fontSize: 14,
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "4px 10px",
    },
  },
  contentRoot: {
    cursor: "pointer",
    minHeight: 150,
    "&:hover": {
      transition: "all ease-in-out 0.5s",
      transform: "scale(1.05)",
    },
    margin: "auto",
  },
}));

export default useStyles;
