import React from 'react';
import { Box } from '@material-ui/core';
import Carousel from "react-multi-carousel";
import { Link } from 'react-router-dom';
import "react-multi-carousel/lib/styles.css";
import { useSelector } from 'react-redux';
import useStyles from './styles';
import { imageUrl } from '../../../helpers/common';
import useViewportSize from '../../../helpers/useViewportSize';

function HeaderCarousel() {
  const classes = useStyles();
  const bannerList = useSelector(state => state.banners['Homepage Banners']);
  const mobileBannerList = useSelector(state => state.banners['Homepage Mobile Banners']);

  const {isMobile} = useViewportSize()

  const currentBanners = isMobile ? mobileBannerList : bannerList
  

  const responsive = {
    default: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };

  return (
    <Box overflow="hidden">
      {bannerList?.length > 0 && <Carousel
        draggable={true}
        keyBoardControl={true}
        responsive={responsive}
        infinite={true}
        partialVisible={"false"}
        autoPlay
        autoPlaySpeed={3000}
        arrows={false}
        dotListClass={classes.bannerCarouselDotList}
        showDots
      >
        {currentBanners?.map(banner => <Link to={`${banner.url}`} key={banner.id} >
          <Box className={classes.carouselItem}>
            <img src={imageUrl(banner.image, 'banners', '1425x450-')} alt={banner?.name} draggable={false} />
          </Box>
        </Link>)}
      </Carousel>}
    </Box>
  )
}

export default HeaderCarousel;
