import { makeStyles } from "@material-ui/core";

export default makeStyles(() => ({
  dash: { height: 5, width: 60, backgroundColor: "#E16434" },
  heading: {
    fontSize: 32,
    marginBottom: 16,
    "@media (max-width: 500px)": {
      fontSize: 27,
      marginBottom: 7,
    },
  },
}));
