import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
  container: {
    paddingTop: 50,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 30,
    },
  },
  heading: {
    color: "#E16434",
    textAlign: "center",
    fontSize: 36,
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
    },
  },
  leftBox: {
    marginTop: 20,
    paddingRight: 50,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      marginBottom: 30,
    },
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    gap: 15,
    marginBottom: 15,
  },
  rightBox: {
    marginTop: 20,
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#eee",
    width: 45,
    height: 45,
    borderRadius: "50%",
  },
  iconBoxtext: {
    fontSize: "16px !important",
    color: "#000",
    "& a": {
      textDecoration: "none",
      color: "#000",
    },
    "& a:hover": {
      textDecoration: "none",
      color: "#E16434",
    },
  },
  btnContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: 18,
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  map: {
    border: 0,
    marginTop: 15,
    marginBottom: 40,
    maxHeight: 400,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 280,
    },
  },
}));
