import { createAction } from 'redux-actions';
import { getFrontendDetails } from '../api/settings';
import { GET_SETTINGS_FAILURE, GET_SETTINGS_SUCCESS } from '../constants/actionTypes';

const getSettingsSuccess = createAction(GET_SETTINGS_SUCCESS);
const getSettingsFailure = createAction(GET_SETTINGS_FAILURE);

export function getSettingsInfo() {
  return dispatch => getFrontendDetails()
    .then(json => dispatch(getSettingsSuccess(json)))
    .catch(err => dispatch(getSettingsFailure(err)));
}
