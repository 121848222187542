import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
  Divider,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateProfile } from "../../../actions/customer";
import { getOrderDetails } from "../../../actions/orders";
import { convertToSlug, imageUrl } from "../../../helpers/common";
import CommonButton from "../../Common/CommonButton";
import { showErrorSnackbar } from "../../../actions/snackbar";
import { LOAD_CART_DATA } from "../../../constants/actionTypes";
import Helper from "../Helper";
import MetaTags from "../../Common/MetaTags";

const getFinalPrice = (product) => {
  // cut mrp and selling price and show only offer price
  if (parseFloat(product.offer_price) > 0) {
    return (
      <Box width='80%'>
        <Box
          display='flex'
          width='100%'
          gridGap={30}
          pt={1}
        >
          <Box
            fontSize={14}
            color='#666'
            style={{ textDecoration: "line-through" }}
          >
            MRP: &#x20b9;{product.mrp}
          </Box>
          <Box
            fontSize={14}
            color='#666'
            style={{ textDecoration: "line-through" }}
          >
            Selling Price: &#x20b9;{product.selling_price}
          </Box>
        </Box>
        <Box fontSize={16} pt={1} style={{ color: "green" }}>
          Offer Price: &#x20b9;{product.offer_price}
        </Box>
      </Box>
    );
  }

  if (parseFloat(product.selling_price) > 0) {
    return (
      <Box
        display='flex'
        alignItems='center'
        width='100%'
        mt={1}
        gridGap={30}
      >
        <Box
          fontSize={14}
          color='#666'
          style={{ textDecoration: "line-through" }}
        >
          MRP: &#x20b9;{product.mrp}
        </Box>
        <Box fontSize={18} style={{ color: "green" }}>
          Selling Price: &#x20b9;{product.selling_price}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      width='100%'
      mt={1}
    >
      <Box fontSize={20} style={{ color: "green" }}>
        Price: &#x20b9;{product.mrp}
      </Box>
    </Box>
  );
};

export default function OrderDetails() {
  const { orderId } = useParams();
  const currentOrder = useSelector((state) => state.orders.currOrder);
  const cartData = useSelector((state) => state.cart.data);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, orderId]);

  const onReorder = (e) => {
    e.preventDefault();

    const productList = Helper.getFinalProductList(cartData, currentOrder);

    localStorage.setItem("hsCartProducts", JSON.stringify(productList));
    setLoading(true);

    dispatch(updateProfile({ cart_data: JSON.stringify(productList) })).then(
      (ack) => {
        setLoading(false);
        dispatch({ type: LOAD_CART_DATA });
        if (ack) navigate("/cart");
        else dispatch(showErrorSnackbar("Sorry! Products cannot be added!"));
      }
    );
  };

  return (
    <Box p={3}>
      <MetaTags title='Order Details' description='' keywords=''></MetaTags>

      <Box m='auto' maxWidth={1200}>
        <Box fontSize={20} color='#666'>
          Order Details
          <div style={{ float: 'right' }}>
            <div>Order ID: <strong>{orderId}</strong></div>
          </div>
        </Box>
        <List>
          {currentOrder.map(({ id, product, total_amount, quantity }) => {
            return (
              <>
                <ListItem
                  alignItems='flex-start'
                  onClick={() =>
                    navigate(`/product/${product?.id}/1/${convertToSlug(product?.name)}`)
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      variant='rounded'
                      alt={product?.name}
                      src={imageUrl(product?.main_image, 'products/' + product.id, '100x120-')}
                      style={{ width: 75, height: 75, marginRight: 20 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box fontWeight={700} fontSize={20}>
                        {product?.name} {product?.id}
                      </Box>
                    }
                    secondary={
                      <Box display={"flex"} justifyContent={"space-between"} fontSize={16}>
                        {getFinalPrice(product)}
                        <Box>{`Quantity : ${quantity}`}</Box>
                      </Box>
                    }
                  />
                </ListItem >
                <Divider />
              </>
            )
          })}
        </List>

        <Box display='flex' justifyContent='flex-end'>
          <CommonButton
            rounded
            loading={loading}
            width={150}
            onClick={onReorder}
          >
            Reorder
          </CommonButton>
        </Box>
      </Box>
    </Box >
  );
}
