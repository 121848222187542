import { Box, Container, Grid, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../Common/CommonButton";
import ReCAPTCHA from "react-google-recaptcha";
import { showErrorSnackbar, showSuccessSnackbar } from "../../actions/snackbar";
import { contactUs } from "../../api/contacts";
import MetaTags from "../Common/MetaTags";
import RoomIcon from "@material-ui/icons/Room";
import CallIcon from "@material-ui/icons/Call";
import useStyles from "./styles";
import EmailIcon from "@material-ui/icons/Email";

export default function ConactUs() {
  const customer = useSelector((state) => state.auth.profile);
  const [email, setEmail] = useState(customer.email ?? "");
  const [name, setName] = useState(customer.name ?? "");
  const dispatch = useDispatch();
  const [subject, setSubject] = useState("");
  const [feedback, setFeedback] = useState("");
  const content = useSelector((state) => state.contents.homepage);
  const frontendData = useSelector((state) => state.contents.frontendData);
  const [loading, setLoading] = useState(false);
  const recaptchaRef = React.createRef();
  const classes = useStyles();

  const company_address = frontendData?.find(
    (data) => data.key === "company_address"
  )?.value;

  const company_contact_number = frontendData?.find(
    (data) => data.key === "company_contact_number"
  )?.value;

  const company_email_address = frontendData?.find(
    (data) => data.key === "company_email_address"
  )?.value;

  const company_map_location = frontendData?.find(
    (data) => data.key === "company_map_location"
  )?.value;

  const google_recaptcha_site_key = frontendData?.find(
    (data) => data.key === "google_recaptcha_site_key"
  )?.value;

  const onSubmit = (e) => {
    e.preventDefault();

    const captcha_value = recaptchaRef?.current?.getValue();
    if (!captcha_value) {
      dispatch(showErrorSnackbar("Please verify captcha!"));
      return false;
    }

    setLoading(true);

    contactUs({ name, email, subject, message: feedback, captcha_value })
      .then((json) => {
        setLoading(false);
        setName("");
        setEmail("");
        setFeedback("");
        setSubject("");
        dispatch(
          showSuccessSnackbar(
            "Your query has been submitted successfully! We will contact you shortly!"
          )
        );
      })
      .catch((error) => {
        console.log("error::", error);
        setLoading(false);
        dispatch(showErrorSnackbar("something went wrong!"));
      });
  };

  return (
    <Container className={classes.container}>
      <Box className={classes.heading}>Contact Us</Box>

      <Grid container>
        <Grid item md={7} sm={12} xs={12}>
          <Box fontFamily='Product Sans' className={classes.leftBox}>
            <Box
              color='#666'
              textAlign='center'
              dangerouslySetInnerHTML={{
                __html: content?.find(
                  (data) => data.name === "contact_us_under_heading_text"
                )?.content,
              }}
            />
            <Box>
              <form onSubmit={onSubmit}>
                <TextField
                  type='name'
                  label='Name'
                  placeholder='Your Name'
                  value={name}
                  fullWidth
                  onChange={(e) => setName(e.target.value)}
                  style={{ marginBottom: 20 }}
                  variant='outlined'
                  required
                />
                <TextField
                  type='email'
                  label='Email'
                  placeholder='Your Email'
                  value={email}
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ marginBottom: 20 }}
                  variant='outlined'
                  required
                />

                <TextField
                  type='subject'
                  label='Subject'
                  placeholder='Your Subject'
                  value={subject}
                  fullWidth
                  onChange={(e) => setSubject(e.target.value)}
                  style={{ marginBottom: 20 }}
                  variant='outlined'
                  required
                />

                <TextField
                  variant='outlined'
                  label='Your feedback'
                  placeholder='Enter your feedback'
                  multiline={true}
                  fullWidth
                  rows={4}
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  required
                />

                <Box className={classes.btnContainer}>
                  {google_recaptcha_site_key ? (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={google_recaptcha_site_key}
                      className='mx-auto d-inline-block'
                    />
                  ) : null}

                  <CommonButton
                    rounded
                    type='submit'
                    loading={loading}
                    width={250}
                  >
                    Submit Feedback
                  </CommonButton>
                </Box>
              </form>
            </Box>
          </Box>

          <MetaTags
            title='Contact Us'
            description='You can reach us for any query or inquiry'
          />
        </Grid>

        <Grid item md={5} sm={12} xs={12}>
          <Box className={classes.rightBox}>
            <Box className={classes.listItem}>
              <Box className={classes.iconBox}>
                <CallIcon />
              </Box>
              <Box className={classes.iconBoxtext}>
                <a
                  href={company_contact_number}
                  target='_blank'
                  rel='noreferrer'
                >
                  {company_contact_number}
                </a>
              </Box>
            </Box>

            <Box className={classes.listItem}>
              <Box className={classes.iconBox}>
                <EmailIcon />
              </Box>
              <Box className={classes.iconBoxtext}>
                <a
                  href={`mailto:${company_email_address}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {company_email_address}
                </a>
              </Box>
            </Box>

            <Box className={classes.listItem}>
              <Box className={classes.iconBox}>
                <RoomIcon />
              </Box>
              <Box className={classes.iconBoxtext}>{company_address}</Box>
            </Box>

            {company_map_location && (
              <iframe
                src={company_map_location}
                width='600'
                height='450'
                className={classes.map}
                allowFullScreen
                loading='lazy'
                referrerPolicy='no-referrer-when-downgrade'
                title='Home Shop Fashion Address'
              ></iframe>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
