import { Box } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { data_url } from "../../helpers/common";
import MetaTags from "../Common/MetaTags";
import RouteInfo from "../Common/RouteInfo";
import AppliedFilters from "./AppliedFilters";
import InfiniteScroller from "./InfiniteScroller";
import ListingLayout from "./ListingLayout";
import useStyles from "./styles";

const ProductListing = () => {
  const classes = useStyles();
  const { categoryId, categoryName, productLabel } = useParams();
  const [routeArray, setRouteArr] = useState([]);

  const categories = useSelector((state) => state.categories.mainCategories);

  const parseCategoryId = parseInt(categoryId, 10);

  useEffect(() => {
    if (categories.length === 0) return;
    const initialRouteInfo = { name: "Home", url: "/" };

    // for category product listing
    if (categoryId && categoryName) {
      const categoryHeaderName = categories?.find(
        (category) => category.id === parseInt(categoryId, 10)
      )?.name;

      if (categoryHeaderName) {
        setRouteArr([
          initialRouteInfo,
          {
            name: categoryHeaderName,
            url: `/category/${categoryId}/1/${categoryName}`,
          },
        ]);
      }
    }

    // for category product listing
    if (productLabel && !categoryName) {
      const featureName = {
        "hot-products": "Hot Products",
        "featured-products": "Featured Products",
        is_offer: "Products on offer",
      };
      setRouteArr([
        initialRouteInfo,
        { name: featureName[productLabel], url: `/products/${productLabel}/1` },
      ]);
    }
  }, [categoryId, categories, categoryName, productLabel]);

  const [searchParams] = useSearchParams();

  const getFilterParams = useCallback(() => {
    let size = searchParams.get("size");
    let color = searchParams.get("color");

    let params = [];

    if (size) {
      params.push(`ProductsSearch[item_size]=${size}`);
    }

    if (color) {
      params.push(`ProductsSearch[item_color]=${color}`);
    }

    if (params.length === 0) {
      params.push('ProductsSearch[show_on_listing_page]=1');
    }

    return params.join("&");
  }, [searchParams]);

  const activeCategory = categories.find(
    (category) => category.id === parseInt(categoryId, 10)
  );

  const Heading = ({ className }) => {
    let text = null;

    if (categoryId) text = activeCategory?.name;
    else if (categoryId === "is_hot") text = "Selling Products";
    else if (categoryId === "is_offer") text = "Product offers";
    else if (productLabel === "featured-products") text = "Featured Products";
    else if (productLabel === "hot-products") text = "Hot Products";

    return (
      <Box className={`${classes.heading} ${className}`} m={3} ml={0} flex={1}>
        {text}
      </Box>
    );
  };

  return (
    <Box>
      {activeCategory ? (
        <MetaTags
          module='categories'
          title={activeCategory?.name}
          description={activeCategory?.description}
          keywords={activeCategory?.name}
          image={data_url + "/categories/" + activeCategory?.image}
        ></MetaTags>
      ) : null}

      <RouteInfo paths={routeArray} />

      <ListingLayout heading={<Heading className={classes.mobileHeading} />}>
        {/* This heading will show only on web */}
        <Heading className={classes.webHeading} />

        <AppliedFilters />

        <InfiniteScroller
          categoryId={parseCategoryId}
          productLabel={productLabel}
          getFilterParams={getFilterParams}
        />
      </ListingLayout>
    </Box>
  );
};

export default ProductListing;
