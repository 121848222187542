import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getProducts } from "../../../actions/products";
import { imageUrl, sortFn } from "../../../helpers/common";
import ProductBox from "../../Common/ProductBox";

function FlashSaleProductsCarousel({ productId }) {
  const dispatch = useDispatch();
  const { currentPage } = useParams();
  const [page, setpage] = useState(1);

  const products = useSelector(
    (state) => state.products[productId ? "similar" : "flashSale"]
  );

  useEffect(() => {
    dispatch(getProducts({ product_labels: productId }, 10, 1));
  }, [dispatch, productId]);

  useEffect(() => {
    if (currentPage) {
      setpage(parseInt(currentPage, 10));
    }
  }, [currentPage]);

  const responsive = {
    largeScreen: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  const windowScroll = () => {
    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });
  };

  return (
    <Box overflow='hidden' maxWidth={1120} margin='auto'>
      <Carousel
        draggable={true}
        keyBoardControl={true}
        responsive={responsive}
        infinite={true}
        partialVisible={"false"}
        autoPlay
        autoPlaySpeed={3000}
        arrows={true}
        showDots
      >
        {products.sort(sortFn).map((product) => (
          <Box
            key={product.id}
            onClick={windowScroll}
            display='flex'
            justifyContent='center'
            mb={5}
          >
            <Link
              to={`/product/${product.id}/${parseInt(page, 10)}/${
                product.url_slug
              }`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Box px={1.5}>
                <ProductBox
                  image={imageUrl(
                    product.main_image,
                    "products/" + product.id,
                    "600x720-"
                  )}
                  title={product.name}
                  price={product.mrp}
                  discountedPrice={product.selling_price}
                  product={product}
                />
              </Box>
            </Link>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
}

export default FlashSaleProductsCarousel;
